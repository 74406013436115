<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <!-- <a class="navbar-brand" href="#">Navbar</a> -->
    <img src="./../assets/icono.png" alt="">
    <button
      class="navbar-toggler"
      type="button"
      data-mdb-toggle="collapse"
      data-mdb-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#Servicios">Servicios</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#Nosotros">Nosotros</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#Experiencia">Experiencia</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#Contacto">Contacto</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<mdb-carousel [controls]="true" [indicators]="true" [animation]="'fade'">
  <mdb-carousel-item>
    <img
      src="./../assets/Banners/Slider2.jpg"
      class="d-block w-100"
      alt="..."
    />
    <div class="carousel-caption d-none d-md-block">
      <h5></h5>
      <p></p>
    </div>
  </mdb-carousel-item>
  <mdb-carousel-item>
    <img
      src="./../assets/Banners/baner4.jpg"
      class="d-block w-100"
      alt="..."
    />
    <div class="carousel-caption d-none d-md-block">
      <h6>Usabilidad</h6>
      <h6>Interacción</h6>
      <h7>A las necesidades de nuestros clientes.<www class="swsmx mx"></www></h7>
    </div>
  </mdb-carousel-item>
  <mdb-carousel-item>
    <img
      src="./../assets/Banners/slider1sws.jpg"
      class="d-block w-100"
      alt="..."
    />
    <div class="carousel-caption d-none d-md-block">
      <h6>Prototipos</h6>
      <h7>Seleccionando areas de oportunidad.</h7>
    </div>
  </mdb-carousel-item>
</mdb-carousel>
<!-- Container for demo purpose -->
<div class="container my-5 py-5 shadow-5">

  <!--Section: Design Block-->
  <section id="Servicios">
    <h3 class="mb-5 text-center" >Servicios</h3>
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="row mb-4">
          <div class="col-md-2 col-2">
            <img src="./../assets/Servicios/iconos/analisis.png" alt="">
          </div>
          <div class="col-md-10 col-10">
            <h5>Análisis del caso de negocios.</h5>
            <p>El análisis de caso de negocio es un proceso crítico en el que se evalúa cuidadosamente cada opción disponible para abordar un problema o aprovechar una oportunidad de negocio.</p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-2 col-2">
           <img src="./../assets/Servicios/iconos/desarrollo.png" alt="">
          </div>
          <div class="col-md-10 col-10">
            <h5>Desarrollo y diseño de procesos y sistematización.</h5>
            <p>El desarrollo de procesos implica la creación de un conjunto de pasos, para la automatización de los procesos y su incorporación en sistemas de software,ayuda a las empresas a mejorar su rendimiento, y aumentar la satisfacción del cliente.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 col-2">
            <img src="./../assets/Servicios/iconos/experienciausuario.png" alt="">
          </div>
          <div class="col-md-10 col-10">
            <h5>Diseño de experiencia de usuario.</h5>
            <p>Es la percepción que un usuario tiene al interactuar con un producto, servicio o sistema; Esto implica crear soluciones que se centren en las necesidades, objetivos y deseos del usuario, y que sean fáciles de usar y entender.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 col-2">
             <img src="./../assets/Servicios/iconos/desarrollo.png" alt="">
          </div>
          <div class="col-md-10 col-10">
            <h5>Sistemas para múltiples formatos de dispositivos (móviles, escritorio).</h5>
            <p>Es un software o plataforma diseñada para funcionar efectivamente en diferentes dispositivos, lo que garantiza una experiencia de usuario consistente.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <img src="./../assets/Servicios/Servicios1.jpg" class="img-fluid" alt="smaple image">
      </div>
    </div>

  </section>
  <!--Section: Design Block-->

</div>
<!-- Container for demo purpose -->
<!-- Container for demo purpose -->
<div class="container my-5 py-5 shadow-5">

  <!--Section: Design Block-->
  <section>
    <div class="row align-items-center">
      <div class="col-lg-6">
        <img src="./../assets/Servicios/Servicios2.jpg" class="img-fluid" alt="smaple image">
      </div>
      <div class="col-lg-6">
        <div class="row mb-4">
          <div class="col-md-2 col-2">
             <img src="./../assets/Servicios/iconos/ecommerce.png" alt="">
          </div>
          <div class="col-md-10 col-10">
            <h5>Desarrollo de comercio electrónico, pagos, conectividad.</h5>
            <p> Es la creación de una plataforma de compra y venta en línea, que incluye la integración de opciones de pago en línea, la gestión de catálogos de productos, la administración de pedidos y envíos, y la seguridad de las transacciones.</p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-2 col-2">
             <img src="./../assets/Servicios/iconos/APIs.png" alt="">
          </div>
          <div class="col-md-10 col-10">
            <h5>API personalizadas con comunicación entre sus aplicaciones y otros sistemas.</h5>
            <p>Una API personalizada es un conjunto de protocolos y herramientas diseñados para permitir la comunicación entre una aplicación o sistema específico y otros sistemas,debe ser segura, eficiente y fácil de usar.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 col-2">
            <img src="./../assets/Servicios/iconos/puestaMarcha.png" alt="">
          </div>
          <div class="col-md-10 col-10">
            <h5>Puesta en marcha de sistemas con capacitación y manuales de operación.</h5>
            <p>Implica la implementación de un nuevo sistema y la provisión de la capacitación necesaria y los manuales de operación para que los usuarios puedan utilizar el sistema de manera efectiva.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 col-2">
            <img src="./../assets/Servicios/iconos/soporte.png" alt="">
          </div>
          <div class="col-md-10 col-10">
            <h5>Soporte técnico y mantenimiento de su Infraestructura.</h5>
            <p>Es garantizar que los sistemas, redes y equipos estén en buen estado de funcionamiento y sean capaces de satisfacer las necesidades del negocio y de los usuarios.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Section: Design Block-->

</div>
<!-- Container for demo purpose -->
<!-- Container for demo purpose -->
<div class="background-verde">
<div class="container my-5 py-5 shadow-5">

  <!--Section: Design Block-->
  <section>

    <h3 class="text-center mb-5">Desarrollo de Software</h3>

    <div class="row align-items-center">
      <div class="col-lg-4 col-md-12">
        <div class="row mb-4">
          <div class="col-3">
            <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
              style="width: 70px; height: 90px;">
              <img src="./../assets/Desarrollo de software/web.png" alt="">
            </div>
          </div>
          <div class="col-09 mb-2">
            <h5>Web Services</h5>
            <p></p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-3">
            <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
              style="width: 70px; height: 90px;">
              <img src="./../assets/Desarrollo de software/nube.png" alt="">
            </div>
          </div>
          <div class="col-9 mb-2">
            <h5>Administración de la nube</h5>
            <p></p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-3">
            <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
              style="width: 70px; height: 90px;">
              <img src="./../assets/Desarrollo de software/hosting.png" alt="">
            </div>
          </div>
          <div class="col-9 mb-2">
            <h5>Hosting</h5>
            <p></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 text-center px-5 mb-5">
        <img src="https://mdbootstrap.com/img/Mockups/Transparent/Small/admin-new1.png" alt="" class="img-fluid">
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="row mb-4">
          <div class="col-3">
            <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
              style="width: 70px; height: 90px;">
              <img src="./../assets/Desarrollo de software/app.png" alt="">
            </div>
          </div>
          <div class="col-9 mb-2">
            <h5>App´s Móviles</h5>
            <p></p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-3">
            <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
              style="width: 70px; height: 90px;">
              <img src="./../assets/Desarrollo de software/expusuarios.png" alt="">
            </div>
          </div>
          <div class="col-9 mb-2">
            <h5>Experiencia de usuarios</h5>
            <p></p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-3">
            <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
              style="width: 70px; height: 90px;">
              <img src="./../assets/Desarrollo de software/reingenieria.png" alt="">
            </div>
          </div>
          <div class="col-9 mb-2">
            <h5>Reingeniería de procesos</h5>
            <p></p>
          </div>
        </div>
      </div>
    </div>

  </section>
  <!--Section: Design Block-->

</div>
</div>
<!-- Container for demo purpose -->
<!-- Container for demo purpose -->
<div class="container my-5 py-5 shadow-5" id="Experiencia">

  <!--Section: Design Block-->
  <section class="text-center">

    <h3 class="mb-5">Experiencia</h3>

    <p class="mb-5 mx-lg-5">
      Mencionamos por giro de Empresas, lo que nos ha permitido adquirir conocimientos solidos en estas áreas.
    </p>

    <div class="row">
      <div class="col-md-4 mb-4 mb-md-0">
        <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
          style="width: 100px; height: 100px;">
          <!-- <i class="fas fa-cogs fa-2x text-primary"></i> -->
          <img src="./../assets/Experiencia/experiencia1.jpg" class="rounded-circle shadow-1-strong mb-4" alt="">
        </div>
        <h5 class="my-4">Diseño Web.</h5>
        <p class="mb-md-0">
          
        </p>
      </div>

      <div class="col-md-4 mb-4 mb-md-0">
        <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
          style="width: 100px; height: 100px;">
         <!-- <i class="fas fa-cogs fa-2x text-primary"></i> -->
          <img src="./../assets/Experiencia/experiencia2.jpg" class="rounded-circle shadow-1-strong mb-4" alt="">
        </div>
        <h5 class="my-4">Seguridad Privada.</h5>
        <p class="mb-md-0">
       
        </p>
      </div>

      <div class="col-md-4 mb-4 mb-md-0">
        <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
          style="width: 100px; height: 100px;">
          <!-- <i class="fas fa-cogs fa-2x text-primary"></i> -->
          <img src="./../assets/Experiencia/experiencia3.jpg" class="rounded-circle shadow-1-strong mb-4" alt="">
        </div>
        <h5 class="my-4"> Automotriz.</h5>
        <p class="mb-0">
        
        </p>
      </div>

    </div>

    <div class="row">
      <div class="col-md-6 mb-4 mb-md-0">
        <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
          style="width: 100px; height: 100px;">
          <!-- <i class="fas fa-cogs fa-2x text-primary"></i> -->
          <img src="./../assets/Experiencia/experiencia4.jpg" class="rounded-circle shadow-1-strong mb-4" alt="">
        </div>
        <h5 class="my-4">Traslado de valores.</h5>
        <p class="mb-md-0">
       
        </p>
      </div>

      <div class="col-md-6 mb-4 mb-md-0">
        <div class="shadow-2 d-flex align-items-center justify-content-center rounded-circle mx-auto"
          style="width: 100px; height: 100px;">
          <!-- <i class="fas fa-cogs fa-2x text-primary"></i> -->
          <img src="./../assets/Experiencia/experiencia5.jpg" class="rounded-circle shadow-1-strong mb-4" alt="">
        </div>
        <h5 class="my-4">Sector Salud.</h5>
        <p class="mb-md-0">
       
        </p>
      </div>
    </div>

  </section>
  <!--Section: Design Block-->

</div>
<!-- Container for demo purpose -->
<!-- Container for demo purpose -->
<div class="background-verde" id="Nosotros">
  <div class="container my-5 py-5 shadow-5 ">

    <!--Section: Design Block-->
    <section class="text-center">
      <h3 class="mb-5">Sobre nosotros</h3>

      <div class="row">
        <div class="col-md-12 col-lg-4 mb-4">
          <img class="rounded-circle shadow-1-strong mb-4"
            src="./../assets/nosotros/empresa.jpg" alt="avatar" style="width: 200px;" />
          <h5 class="mb-3">Empresa</h5>
          <p class="font-weight-bold text-primary"></p>
          <p>
            <i class=></i>
            Estamos comprometidos en brindar, la mejor experiencia tecnológica, durante nuestros más de 14 años de experiencia, hemos desarrollado gran variedad de soluciones de alta calidad.
          </p>
          <ul class="list-unstyled d-flex justify-content-center text-muted mb-0">
          </ul>
        </div>
        <div class="col-md-6 col-lg-4 mb-4">
          <img class="rounded-circle shadow-1-strong mb-4"
            src="./../assets/nosotros/equipo.jpg" alt="avatar" style="width: 200px;" />
          <h5 class="mb-3">Equipo Profesional</h5>
          <p class="font-weight-bold text-primary"></p>
          <p>
            <i class=></i>
            Contamos con profesionales altamente calificados, comprometidos,dedicados a resultados, con una amplia experiencia en sus respectivas areas de especialización.
          </p>
          <ul class="list-unstyled d-flex justify-content-center text-muted mb-0">
          </ul>
        </div>
        <div class="col-md-6 col-lg-4 mb-4">
          <img class="rounded-circle shadow-1-strong mb-4"
            src="./../assets/nosotros/Infraestructura.jpg" alt="avatar" style="width: 200px;" />
          <h5 class="mb-3">Infraestructura</h5>
          <p class="font-weight-bold text-primary"></p>
          <p>
            <i class=""></i>
            Nuestras instalaciones estan equipadas  con la capacidad de procesamiento, almacenamiento y conectividad para brindar soluciones tecnológicas eficientes y efectivas.
          <p class="font-weight-bold text-primary"></p>
         <ul class="list-unstyled d-flex justify-content-center text-muted mb-0">
          </ul>
        </div>
      </div>
    </section>
    <!--Section: Design Block-->

  </div>
</div>
<!-- Container for demo purpose -->
<footer id="Contacto" class="text-center text-white" style="background-color: #f1f1f1;">
  <!-- Grid container -->
  <div class="container pt-4">
    <!-- Section: Social media -->
    <section class="mb-4">
      <!-- Facebook -->
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="#!"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-facebook-f"></i
      ></a>

      <!-- Twitter -->
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="#!"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-twitter"></i
      ></a>

      <!-- Google -->
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="#!"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-google"></i
      ></a>

      <!-- Instagram -->
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="#!"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-instagram"></i
      ></a>

      <!-- Linkedin -->
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="#!"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-linkedin"></i
      ></a>
      <!-- Github -->
      <a
        class="btn btn-link btn-floating btn-lg text-dark m-1"
        href="#!"
        role="button"
        data-mdb-ripple-color="dark"
        ><i class="fab fa-github"></i
      ></a>
    </section>
    <!-- Section: Social media -->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center text-dark p-3" style="background-color: rgba(0, 0, 0, 0.2);">
    © 2020 Copyright:
    <a class="text-dark" href="https://www.swsmx.mx/">swsmx.mx</a>
  </div>
  <!-- Copyright -->
</footer>